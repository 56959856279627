export class Ledger {
    id = 0;
    ledgerName;
    bankName;
    iban;
    invoicePrefix;
    bic;
    assign(other) {
        Object.assign(this, other);
    }
}
