import axios from "axios";
import { Ledger } from "@/model/Ledger";
export default class LedgerService {
    loadAllLedgers() {
        return axios.get('ledger/all', {
            withCredentials: true
        }).then(response => {
            return response.data;
        });
    }
    saveLedger(ledger) {
        return axios.post('ledger', ledger, {
            withCredentials: true
        }).then(response => {
            const ledger = new Ledger();
            ledger.assign(response.data);
            return ledger;
        });
    }
    deleteLedger(ledger) {
        return axios.delete('ledger/' + ledger.id, {
            withCredentials: true
        });
    }
}
