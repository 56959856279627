import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { "data-cy": "orderRow" };
const _hoisted_2 = { class: "text-start" };
const _hoisted_3 = { class: "text-start" };
const _hoisted_4 = { class: "text-start" };
const _hoisted_5 = { class: "text-start" };
const _hoisted_6 = { style: { "width": "30%" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_status_display = _resolveComponent("status-display");
    return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
        _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.formatter.contractNumber(_ctx.order)), 1),
        _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.order.customer.firstname) + " " + _toDisplayString(_ctx.order.customer.lastname), 1),
        _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.formatter.date(_ctx.order.orderDatetime)), 1),
        _createElementVNode("td", _hoisted_5, [
            _createVNode(_component_status_display, {
                status: _ctx.order.orderStatus
            }, null, 8, ["status"])
        ]),
        _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.order.customerRemarks), 1)
    ]));
}
